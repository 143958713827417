body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay{
  background-color:rgba(0,0,0,0.7);
  position:absolute;
  width:100%;
  height:100%;
  z-index:1500;
}

.modalContainer{
  max-width: 900px;
  width:100%;
  position:relative;
  margin:0 auto;
  margin-top:80px;
}

.closeModal, .right{
float:right;
}

.card-title{
  width:100%;
}

.red, .error_holder{
  color:red;
}

.error_holder{
font-size:13px;
}

.row_small{
font-size:13px;
}

.label-holder{
  display:inline-block;
  width:120px;
  background-color:rgba(0,0,0,0.1);
  padding-left:7px;
  margin-right:7px;
}

.label-block{
  font-size:14px;
}
